import React from "react";
import { useState } from "react";

import "../index.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Carousel from "react-bootstrap/Carousel";

import { StickyContainer, Sticky } from "react-sticky";
import ProjectHeader from "../page-elements/ProjectHeader";
import YoutubePlayer from "../page-elements/YoutubePlayer";
import ImageCarousel from "../page-elements/ImageCarousel";
import NextProject from "../page-elements/NextProject";

function Nova(props) {
    var slug = "nova";
    var [rMore1, setRMore1] = useState(false);
    var [rMore2, setRMore2] = useState(false);
    var [rMore3, setRMore3] = useState(false);

    var collaborators = {};

    var skills = {
        "Solidworks": "#",
        "Rhino3D": "#",
        "Grasshopper": "#",
        "Keyshot": "#",
        DFA: "#",
        CMF: "#",
        "FEA": "#"
    };

    return (
        <div>
            {props.topbar ? <div className="topbar-spacer"></div> : null}
            <ProjectHeader
                title="Fold"
                slug="nova"
                desc="To address the resurgence in the vinyl music industry, I aimed to create a modernised version of record players that was focused on portability and robustness."
                collaborators={collaborators}
                completed="Jan-Mar 2023"
                skills={skills}
                solo
            />
            <Col xs={12} className="project-col">
                <Image className="project-image3" fluid src={`./media/${slug}/image14.jpg`} alt=" " />
            </Col>

            <Row className="project-row" id="hcd">
                <Col xs={12} md={6} className="project-col">
                    <h1 className="mta">'The Return of Vinyl'</h1>
                    <div className="mb10 jt">
                        In the attempt to stand out and continue playing unique soundtracks, DJs and Producers have gone back to finding
                        original tunes through vinyl records. However, the design of record players hasn't changed over the past 30 years...
                    </div>
                    <div className="mba">
                        {rMore1 ? (
                            <div className="read-more-text mba">
                                <div className="mb10 jt">
                                    Therefore, I wanted to re-think the traditional record player and modernise it into a portable and robust alternative -
                                    taking influence from SONY's 'Soundburger' Design. Through evaluating previous attempts at portable record players, I also looked into
                                    vibration dampening technologies to maximise the listening quality. 
                                </div>
                            </div>
                        ) : (
                            <a
                                href="#hcd"
                                className="read-more"
                                onClick={() => {
                                    setRMore1(true);
                                }}
                            >
                                Read more...
                            </a>
                        )}
                    </div>
                </Col>
                <Col xs={12} md={6} className="project-col" style={{ textAlign: "center" }}>
                    <Image className="project-image3" fluid src={`./media/${slug}/Jumbi.jpg`} alt=" " />
                </Col>
            </Row>
            <Row className="project-row">
                <Col xs={12} md={6} className="project-col" style={{ textAlign: "center" }}>
                    <Image fluid src={`./media/${slug}/sketches.jpg`} alt=" " />
                </Col>
                <Col xs={{ span: 12, order: "first" }} md={{ span: 6, order: "last" }} className="project-col">
                    <h1 className="mta">Concept Exploration</h1>
                    <div className="mb10 jt">
                        In order to improve the portability of the record player, I explored the possibility of a foldable version that greatly
                        reduced the product footprint in comparison to past efforts.
                    </div>
                    <div className="mba jt">- collection of concept sketches for Fold.</div>
                </Col>
            </Row>
            <Row className="project-row">
                <Col xs={12} md={6} className="project-col">
                    <h1 className="mta">Iterative Design Approach</h1>
                    <div className="mb10 jt">
                        Through Rhino3D and Grasshopper, I was able to quickly experiment with the overall form and iterate through distinct speaker
                        pattern designs.
                    </div>
                    <div className="mba jt" style={{ textAlign: "right"}}>Grasshopper Speaker and Form iterative models -</div>
                </Col>
                <Col xs={12} md={6} className="project-col" style={{ textAlign: "center" }}>
                    <Image fluid src={`./media/${slug}/iterations1.jpg`} alt=" " />
                </Col>
            </Row>
            <Row className="project-row">
                <h1 className="" id="develop">
                    Lo-Fi Physical Prototyping
                </h1>
                <div className="mb30 jt">
                    To improve the UX of Fold, lo-fi prototypes where made to help determine the optimal button layout prior to modelling it. Furthermore, different
                    folding mechnanisms were tested, and a rotational mechanism was chosen to help reduce the footprint of the record player the most.
                </div>
                <Image fluid src={`./media/${slug}/prototypingFold.jpg`} alt=" " />
            </Row>

            <Row className="project-row">
                <h1 className="" id="develop">
                    Fold Final Design
                </h1>
                <div className="mb30 jt">
                    Once the layout and mechanism for Fold had been finalised, a full 3D model was developed. Fold was designed to have a small footprint, allowing
                    users to pack it away in their bag. The design also ensured that the needle - used to play the record - was securely stored during transit.
                </div>
                <Image fluid src={`./media/${slug}/orthographic.jpg`} alt=" " />
            </Row>
            <Row className="project-row">
                <Col xs={12} md={6} className="project-col">
                    <h1 className="mta">How it works?</h1>
                    <div className="mb10 jt">
                        The Fold record player's main mechanism works in 4 main steps. Firstly the user folds out the bottom platter. Then the record is placed on
                        the pad and the top platter is folded out. Once aligned the user clamps the two platters in place. The record is now ready for listening.
                    </div>
                    <div className="mba jt" style={{ textAlign: "right"}}>Steps to play a record using Fold -</div>
                </Col>
                <Col xs={12} md={6} className="project-col" style={{ textAlign: "center" }}>
                    <ImageCarousel slug={slug} numImages={4} gallerySlug="gallery1" />
                </Col>
            </Row>
            <Row className="project-row">
                <Col xs={12} md={6} className="project-col" style={{ textAlign: "center" }}>
                    <ImageCarousel slug={slug} numImages={2} gallerySlug="gallery3" />
                </Col>
                <Col xs={{ span: 12, order: "first" }} md={{ span: 6, order: "last" }} className="project-col">
                    <h1 className="mta">Vibrational Dampening</h1>
                    <div className="mb10 jt">
                        To provide a better listening experience than previous products, vibration-dampening pads were added to the record platters. These help prevent 
                        the risk of the the needle 'scratching' or 'skipping' during the record playback. The structure of the pads was determined through simulating the vibrational
                        conditions using Finite Element Analysis.
                    </div>
                    <div className="mba jt">- development of the dampening pads</div>
                </Col>
            </Row>
            <Row className="project-row" id="hcd">
                <Col xs={12} md={6} className="project-col">
                    <h1 className="mta">Internal Considerations</h1>
                    <div className="mb10 jt">
                        The project brief was more focused on the external aesthetic and concept of the product over the internal functionality. Having said this, I
                        still ensured to roughly model the key internal components to ensure that the casing of Fold was of a feasable size.
                    </div>
                    <div className="mba jt" style={{ textAlign: "right"}}>Exploded view of the Fold record player -</div>
                </Col>
                <Col xs={12} md={6} className="project-col" style={{ textAlign: "center" }}>
                    <Image className="project-image3" fluid src={`./media/${slug}/exploded.jpg`} alt=" " />
                </Col>
            </Row>

            <Image className="project-image3" fluid src={`./media/${slug}/foldhero.jpg`} alt=" " />

            <NextProject name="Tempo" url="/tempo" />
        </div>
    );
}

export default Nova;
