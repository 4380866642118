import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from "./pages/Home";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nova from './pages/Nova';
import Opaque from './pages/Opaque';
import Foto from './pages/Foto';

function App() {
  const breakpoint = 575;
  const [screenLarge, setScreenLarge] = useState(window.innerWidth > breakpoint);

  useEffect(() => {
    const handleWindowResize = () => setScreenLarge(window.innerWidth > breakpoint);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <Router>
      {screenLarge ? null : <Topbar />}
      <Container fluid>
        <Row>
          {screenLarge ? <Sidebar /> : null}
          <Col className="main-container">
            <Routes>
              <Route path="/" element={<Home topbar={!screenLarge} />} />
              <Route path="/fold" element={<Nova topbar={!screenLarge} />} />
              <Route path="/tempo" element={<Foto topbar={!screenLarge} />} />              
              <Route path="/ourarise" element={<Opaque topbar={!screenLarge} />} />
              {/* <Route path="/cv" element={() => {window.location.href = "/Oliver_Colebourne_CV.pdf"}} /> */}
              <Route path="/" element={() => {window.location.href = "/"}} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </Router>
  );
}

export default App;
