import React from "react";
import { Camera, Mic } from "react-bootstrap-icons";

import "../index.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Carousel from "react-bootstrap/Carousel";

import { StickyContainer, Sticky } from "react-sticky";
import ProjectHeader from "../page-elements/ProjectHeader";
import YoutubePlayer from "../page-elements/YoutubePlayer";
import ImageCarousel from "../page-elements/ImageCarousel";
import NextProject from "../page-elements/NextProject";

function Foto(props) {
    var slug = "foto";
    var desc =
        "Tempo is a novel form of digital map, taking away the need for annoying speech-based commands - 'Turn Right in 300 yds'. This works through spatialising the user's playlist, allowing them to intuitively follow their music to the destination.";

    var collaborators = {};

    var skills = {
        "Audio Engineering": "#",
        "OSC": "#",
        "Human Centred Design": "#",
        "UI/UX": "#",
        "Optimisation": "#",
        "Data Analysis": "#",
    };

    const header_image = `./media/${slug}/header.jpg`;


    // style={{ maxWidth: "60%", minWidth: "200px", marginRight: "10px" }}

    return (
        <div>
            {props.topbar ? <div className="topbar-spacer"></div> : null}
            <ProjectHeader
                title="Tempo"
                slug={slug}
                desc={desc}
                collaborators={collaborators}
                completed="Jan - Jun 2024, Masters Project"
                skills={skills}
                solo
            />
            <Row className="project-row">
                <h1>This project is coming soon...</h1>
                <div className="mb10 jt">
                        In the meantime, check out the 
                        {" "}<a href="https://drive.google.com/file/d/1VdHbwrBs1tSfvODSYGBG9GEraT8YlzCF/view?usp=sharing" target="_blank">
                            academic report
                        </a>{""} I wrote that summarises the research, development and testing of Tempo!
                    </div>
            </Row>
            <NextProject name="Home" url="/" />
        </div>
    );
}

export default Foto;
