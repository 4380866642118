import React from "react";
import { useState } from "react";

import "../index.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Carousel from "react-bootstrap/Carousel";

import { StickyContainer, Sticky } from "react-sticky";
import ProjectHeader from "../page-elements/ProjectHeader";
import YoutubePlayer from "../page-elements/YoutubePlayer";
import ImageCarousel from "../page-elements/ImageCarousel";
import NextProject from "../page-elements/NextProject";

function Opaque(props) {
    var slug = "Opaque";
    var [rMore1, setRMore1] = useState(false);
    var [rMore2, setRMore2] = useState(false);
    var [rMore3, setRMore3] = useState(false);

    var collaborators = {
        "Benjamin Lovell": "",
        "Ruby Grut": "",
        "Archie Bond": "",
        "Rosie Davies": "",
    };

    var skills = {
        "IoT": "#",
        "CAD": "#",
        "Computer Vision": "",
        "Arduino": "",
        "Electronics": "",
        "Rendering": "",
        "UI/UX": "",


    };

    return (
        <div>
            {props.topbar ? <div className="topbar-spacer"></div> : null}
            <ProjectHeader
                title="OURA Rise"
                slug="opaque"
                desc="A first-of-its-kind IOT intervention to aid sufferers of sleep paralysis. NB: Oura were not involved with the development of this device."
                collaborators={collaborators}
                completed="Jan-Jun 2022"
                skills={skills}
            />
            <div className="mb50">
                <YoutubePlayer youtubeId="t2XjDZ6wGeQ" />
            </div>
            <Row className="project-row" id="">
                <Col xs={12} md={12} className="project-col">
                    <h1 className="mta">What is sleep paralysis?</h1>
                    <div className="mb10 jt">
                    Sleep paralysis is a phenomenon where a person is temporarily unable to move or speak while falling asleep or waking up.
                    During an episode of sleep paralysis, the individual is conscious but experiences a sense of immobility. It can be accompanied by vivid hallucinations and a feeling of pressure on the chest.
                    These episodes typically last from a few seconds to a couple of minutes and can be very traumatic. Currently, there are no known effective cures for this condition, therefore we wanted to 
                    create a device that monitored and assisted sufferers of sleep paralysis.
                    </div>
                </Col>
            </Row>

            <Row className="project-row" id="">
                <Col xs={12} md={6} className="project-col">
                    <h1 className="mta">Introducing OURA Rise</h1>
                    <div className="mba jt">
                        OURA Rise is a two-part IoT system including a ceiling mounted device that is wirelessly linked to the OURA Gen 4 Ring. Through computer vision and high accuracy heart rate monitors,
                        OURA Rise is able to identify when a user is experiencing a sleep paralysis episode and effectively bring them out of the unpleasant state.
                        This was achieved after identifying and researching into three intervention opportunities.
                    </div>
                </Col>
                <Col xs={12} md={6} className="project-col">
                    <Image className="project-image3" fluid src={`./media/${slug}/image1.jpg`} alt=" " />
                </Col>
            </Row>
            <h1 className="mta">Three identified opportunities...</h1>
            <Row className="project-row" id="">
                <Col xs={12} md={4} className="project-col">
                    <Image className="project-image5" fluid src={`./media/${slug}/image2.jpg`} alt=" " />
                    <div className="mba jt">
                        <b>OPEN EYES - </b>During an episode of sleep paralysis, the user is able to open and move their eyes around even though the rest of their body is paralysed.
                        This can help distinguish between someone sleeping normally, and someone experiencing an episode.
                    </div>
                </Col>
                <Col xs={12} md={4} className="project-col">
                    <Image className="project-image5" fluid src={`./media/${slug}/image3.jpg`} alt=" " />
                    <div className="mba jt">
                        <b>RAISED HEARTBEAT - </b>Due to the traumatic nature of sleep paralysis, many sufferers begin to panic during the episodes. This results
                        in a raised heartbeat, forming another indication of sleep paralysis taking place.
                    </div>
                </Col>
                <Col xs={12} md={4} className="project-col">
                    <Image className="project-image5" fluid src={`./media/${slug}/image4.jpg`} alt=" " />
                    <div className="mba jt">
                        <b>SLEEPING POSITION - </b>Research shows how sufferers of sleep paralysis often experience episodes when they are sleeping on their back and facing 
                        upwards. This provided an opportunity of thinking about where we could position the detection device to monitor the user accurately.
                    </div>
                </Col>
            </Row>

            <Row className="project-row">
                <h1 className="" id="develop">
                    The System
                </h1>
                <div className="mb30 jt">
                    Through taking advantage of the three identified opportunities, we devised an IoT intervention that could effectively identify, track and relieve
                    episodes of sleep paralysis. The simplified 3-part system diagram is found below.
                </div>
                <Image fluid src={`./media/${slug}/systemDiagram.jpg`} alt=" " />
            </Row>

            <Row className="project-row">
                <h1 className="" id="develop">
                    #1 Ceiling Mount
                </h1>
                <div className="mb30 jt">
                    The purpose of the ceiling mount is to determine whether the user is experiencing a bad-dream or a sleep paralysis episode. This is achieved through
                    a ceiling mounted camera that users computer vision to determine whether the user's eyes are open or closed, thus providing the appropriate indication. The ceiling mount only
                    captures the user's eye-state once the OURA Ring has detected a raise in heartrate.
                </div>
                <Image fluid src={`./media/${slug}/exploded.jpg`} alt=" " />
            </Row>
            <Row className="project-row" id="HCD">
                <Col xs={12} md={6} className="project-col">
                    <h1 className="mta">#2 OURA Ring</h1>
                    <div className="mba">
                        The OURA Ring sub-system was designed to fit in with existing components on the commercially available version. The heart rate sensor sends a bluetooth
                        signal to the ceiling mount once an abnormally large heart rate change is measured. Once verified as sleep paralysis, the ceiling mount then sends a bluetooth
                        signal back to the ring to stimulate movement through haptic feedback.
                    </div>
                </Col>
                <Col xs={12} md={6} className="project-col">
                    <video
                    className="project-video2 mb50"
                    autoPlay
                    loop
                    muted
                    playsInline
                    src={`./media/${slug}/ouraRing.mp4`}
                    type="video/mp4"
                ></video>
                </Col>
            </Row>

            <Row className="project-row">
                <h1 className="" id="develop">
                    Functional Prototyping
                </h1>
                <div className="mb30 jt">
                    In order to prove the concept, a fabricated prototype was constructed. This featured a network of ESP32 Microcontrollers that wirelessly communicated
                    to emulate the components of the OURA Rise system. For the OURA Ring, bluetooth communication allowed for the Heartrate Sensor to alert the Ceiling Mount
                    once the user's heart rate had risen above a threshold. Once alerted, the Ceiling Mount would send a livestream to a web-server that carried out computer vision 
                    analysis on the stream and determined whether the user is categorically experiencing a sleep paralysis episode. The electronics were encased in a high-fidelity prototype of the Ceiling Mount,
                    this could be constructed in 12 assembly steps.
                </div>
                <Image fluid src={`./media/${slug}/prototyping.jpg`} alt=" " />
            </Row>

            <Row className="project-row" id="">
                <Col xs={12} md={6} className="project-col">
                    <h1 className="mta">Eye Detection Software</h1>
                    <div className="mba">
                        The webserver allowed for the stream of the user sleeping to be analysed. I developed a Python script that ran on an AWS EC2 Instance to regularly check the stream.
                        Python's OpenCV Library was used to apply a face mesh to the user, and specific nodes were selected to identify the user's eye. The ratio between the horizontal and vertical 
                        distance of the eye was then used to determine whether the eye was open or closed.
                    </div>
                </Col>
                <Col xs={12} md={6} className="project-col">
                    <ImageCarousel slug={slug} numImages={3} gallerySlug="gallery1" />
                </Col>
            </Row>

            <Row className="project-row">
                <h1 className="" id="develop">
                    Contextual Testing
                </h1>
                <div className="mb30 jt">
                    Once the prototype had been built, we tested the system in the context of the user's bedroom in order to help identify any improvements that we could make to the device.
                    The project was deemed effective and ultimately resulted in OURA Rise winning the {" "}
                <a href="https://www.imperial.ac.uk/design-engineering/study/meng/student-prizes/desire-awards/" target="_blank">
                    DESIRE Award 2022
                </a>{""}.
                </div>
                <Image fluid src={`./media/${slug}/ouraContext.jpg`} alt=" " />
            </Row>
            <Row className="project-row">
            <Image fluid src={`./media/${slug}/ouraFinal.jpg`} alt=" " />
            </Row>


            <NextProject name="Fold" url="/fold" />
        </div>
    );
}

export default Opaque;
